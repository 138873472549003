document.addEventListener("DOMContentLoaded", () => {
  const headerBlock = document.querySelectorAll(
    "#component_footer .block-header"
  )
  headerBlock.forEach((_, index) => {
    const el = headerBlock[index]

    el.onclick = () => {
      el.parentNode.classList.toggle("_active")
    }
  })

  const menuBtn = document.getElementById("menu-btn")
  menuBtn.onclick = e => {
    e.preventDefault()

    menuBtn.classList.toggle("_active")

    document.getElementById("mobile-menu").classList.toggle("_visible")
    document.documentElement.classList.toggle("_hidden")
  }

  const modalCityList = document.querySelectorAll("#modal-city .city")
  modalCityList.forEach((_, index) => {
    const el = modalCityList[index]

    el.onclick = e => {
      e.preventDefault()

      modalCityList.forEach(el => el.classList.remove("_active"))

      el.classList.add("_active")
      document.getElementById("city_id").value = el.getAttribute("value")
    }
  })

  window.closeModal = () => {
    document.documentElement.classList.remove("_hidden")
    document
      .querySelectorAll(".modal")
      .forEach(m => m.classList.remove("_visible"))
  }

	$('.top-links__toggle').click(function (e) { 
		e.preventDefault();
		$('.top-links__toggle').not(this).closest('.top-links__toggle-wrapper').find('.top-links__menu').slideUp(100);
		$(this).closest('.top-links__toggle-wrapper').find('.top-links__menu').slideToggle(100);
	});

	$('#open-search').click(function (e) { 
		e.preventDefault();
		$('.hidden-search-mobile').slideToggle(300);
	});

	$(document).mouseup( function(e){ // событие клика по веб-документу
		var div = $( ".hidden-search-mobile" ); // тут указываем ID элемента
		if ( !div.is(e.target) // если клик был не по нашему блоку
		    && div.has(e.target).length === 0 ) { // и не по его дочерним элементам
			div.slideUp(); // скрываем его
		}
	});
})
