import '../styles/index.scss'
import '../styles/new-pages.scss'

import './common/twinspark'
import './common/popup-banner'
import './common/header'
import './common/scroll-top'
import './common/common'

import * as forms from './forms'

document.addEventListener("DOMContentLoaded", () => {
  $('.product-favorites-add:not([ts-action])').click(function(e) {
    e.preventDefault();

    let product_id = $(e.currentTarget)[0].dataset['product'];
    let model_name = $(e.currentTarget)[0].dataset['modelname'];
    let price = $(e.currentTarget)[0].dataset['price'];

    let fav_use = $(this).find('use');
    let fav_text = $(this).find('.fav-btn-text');

    $(this).toggleClass("_active")

    var added = $(this).hasClass('_active')
    fav_text.text(added ? 'Добавлено в избранное' : 'В избранное')
    fav_use.attr('xlink:href', added ? '#star2-fill' : '#star2')

    send_add_favorites_ajax(product_id, model_name, added, price);
  });
})
