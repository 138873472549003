import $ from "jquery"

function fmtParam(params, param, fmter) {
    if (params[param]) {
        var val = params[param]
        delete params[param]
        if (fmter) {
            return fmter(val)
        } else {
            return val
        }
    }
}

var rimSlider = document.getElementById('nouislider-2')
var rimStdProps = {}
if (rimSlider) {
    rimStdProps = rimSlider.dataset
}

var rim_formatters = {
    brands: function(v) {
        if (!v) return v
        if (typeof v == 'string') {
            return v
        } else {
            return Array.from(v).join("-or-")
        }
    },
    size: null,
    unite_pcd: null,
    dia: null,
    width: function(v) {return v > 0? v.replace(".", "-").replace("-0", "") + 'j': null},
    et_length__lte: function(v) { return v != parseInt(rimStdProps.minValue)? 'et'+v: null},
    type: (v)=> (v.length ? v: null),
    wheels_width_max: function(v) { return v != 45? v.replace(".", "-").replace("-0", "")+'jmax': null},
    wheels_et_max: function(v) { return v != parseInt(rimStdProps.maxValue)? 'et-max'+v: null},
    parent_model: (v) => 'for-'+v,
    sub_model: (v) => v,
    beginyear: (v) => v,
    modification: (v) => v,
    diameter: (v) => v,
    by_car_rim_type: (v) => v == "forged" ? v : null,
}
var tire_formatters = {
    parent_model: (v) => `for-${v}`,
    sub_model: (v) => v,
    beginyear: (v) => v,
    modification: (v) => v,
    diameter: (v) => v,
    type: (v) => (v == 'shipovannye' || v == 'neshipovannye') ? v : `season-${v}`,
}
var acs_formatters = {}
var acsForm = document.querySelector("#component_catalog-filter form")
if (acsForm) {
    var acsFormData = new FormData(acsForm)
    acsFormData.forEach(function(value, key){
        acs_formatters[key] = null;
    })
}

function collectFilterString(params, startPath, formatters) {
    var pathParams = [startPath];
    Object.keys(formatters).forEach(function(k) {
        var v = fmtParam(params, k, formatters[k])
        if (v && (v+"").length > 0) { pathParams.push(v) }
    })
    var path = pathParams.join('/')
    return path + "/"
}
var filter_paths = {'/shiny': tire_formatters, '/diski': rim_formatters, '/aksessuary': acs_formatters}

window.filterUrl = function(params, path) {
    var query_string = '';
    Object.keys(filter_paths).forEach(function(it) {
        if ((path || breadcrumbs).startsWith(it)) {
            query_string = collectFilterString(params, path || it, filter_paths[it])
        }
    })
    return query_string;
}

window.applyFilter = function(params, path) {
    let query_string = filterUrl(params, path)
    window.location.href = query_string + (params.promo ? `?promo=${params.promo}` : '');
}

window.send_add_favorites_ajax = function send_add_favorites_ajax(product_id, model_name, added, price) {
    $.ajax({
        headers: {
            "X-CSRFToken": '{{ csrf_token }}'
        },
        type: "POST",
        url: '/favorites/add/' + product_id,
        data: {
            'product_id': product_id,
            'model_name': model_name
        },
        dataType: 'json',
        success: function (data) {
            var count = parseInt($("#fav-counter").text() || '0')
            count = added? count+1: count-1
            if (!document.getElementById('fav-counter')) {
                $('#favorites-button').append("<span class='counter'>"+count+"</span>")
            }
            $("#fav-counter").text(count)
            _tmr.push({type: "reachGoal", id: 3364384, goal: "Dobavleniye_v_izbrannoye", value: price, params: {product_id: product_id}});
            if(window.location.pathname == '/favorites/'){
                location.reload();
            }
        }
    });
};


$('.branch-block').click(function () {
    var iframe_link = $(this).data('iframe-link');
    if (iframe_link) {
        $('.map-iframe').attr('src', iframe_link);
    }
})

$('.payment-item').click(function () {
    if ($(this).data('show-file-block') == true) {
        $('#basket-file-wrap').fadeIn();
    } else {
        $('#basket-file-wrap').attr("style", "display: none !important");
    }
});


function addSelOptions(dataList, el) {
    dataList.forEach(item => el.append(
        $('<option>', {
            value: item.urlname.replaceAll("_", "-"),
            text: item.name
        }))
    )
}

function getCarSearchRimType(form) {
  if (!form) {
    return null;
  }

  form = $(form)
  if (form.length && form[0].getAttribute('action').indexOf('diski') != -1) {
    return window.location.href.indexOf('/forged/') != -1 ? 'forged' : 'alloy';
  }
  
  return null;
}

function getSearchParams(k) {
    var p = {};
    location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
        p[k] = v
    })
    return k ? p[k] : p;
}

function createQueryString(params) {
    let string = '';
    let i = 0
    $.each(params, function (key, value) {
        if (i == 0) {
            string += '?';
            string += key + '=' + value;
        } else {
            string += '&' + key + '=' + value;
        }
        i += 1;
    })
    return string;
};

window.applyFastFilter = function(el) {
    let params = Object.assign({},window.activeFilters)

    let param = $(el).attr('name');
    let value = $(el).val();

    delete params['page']
    if (value) {
        params[param] = value;
    } else {
        delete params[param]
    }

    let path = undefined;
    if (window.location.pathname.startsWith("/aksessuary")) {
      params = {}
      const arr = window.location.pathname.split("/")
      
      arr.slice(3).filter(p => p).forEach(p => {
        let arr2 = p.split("-")
        params[arr2[0]] = p
      })
      params[param] = value
      if (arr.length == 5 && !value) {
        path = `/aksessuary/${arr[2].replace('-filter', '')}`
      } else if(window.location.pathname.indexOf("-filter") != -1) {
        path = `/aksessuary/${arr[2]}`
      } else if (arr.length >= 3) {
        path = `/aksessuary/${arr[2]}-filter`
      }
    }

    applyFilter(params, path)
}

$(document.body).on('change', '.fast-select', function () {
    applyFastFilter(this)
});

window.submitFilter = function(el) {
    var fd = new FormData(el)
    var params = {}
    fd.forEach(function(value, key){
        if (value.length == 0) return
        if (params[key]) {
            if (Array.isArray(params[key])) {
                params[key] = params[key].concat(value)
            } else {
                params[key] = [params[key], value]
            }
        } else {
            params[key] = value;
        }
    })
    let action = el.getAttribute('action')
    let empty = el.dataset.empty
    if (empty && Object.values(params).length == 0) {
        action = empty
    }
    window.applyFilter(params, action.length? action: null)
}

$(document.body).on('submit', '#component_catalog-filter form:not(.dis-uni), .aside .js-by-auto-filter', function(e) {
    e.preventDefault()
    sessionStorage.setItem('asideStatus', 'closed')
    submitFilter(e.currentTarget)
});
